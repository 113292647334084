import React, { Fragment, useContext } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';

import * as modalTypes from '../types/modalTypes';
import ModalContext from '../store/modalContext';
import SEO from '../components/SEO';
import { homeStrings } from '../consts/strings';
import mq from '../style/mq';
import { Container, Inner } from '../components/Elements';
import PlaySrc from '../images/play.png';
import { colors, z } from '../consts/style';

const Wrapper = styled.div`
  position: absolute;
  overflow: hidden;
  top: 7.2rem;
  left: 0;
  height: calc(100% - 7.2rem);
  width: 100%;

  ${Container} {
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
  }
  ${Inner} {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10vh;
  }
`;

const Play = styled.img.attrs({ src: PlaySrc })`
  width: 6rem;
  cursor: pointer;

  ${mq.tabletSmall`
      margin-top: 30vh;
  `};
`;

const PlayText = styled.h4`
  color: white;
  font-family: 'Black Ops One';
  font-weight: normal;
  margin-bottom: 6rem;
`;

const BgImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  z-index: 0;

  &::before {
    content: '';
    background: rgba(81, 93, 117, 0.45);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  ${mq.tabletSmall`
      opacity: 0;
  `};
`;

const BgImgMobile = styled(BgImg)`
  opacity: 0;
  ${mq.tabletSmall`
    opacity: 1;
  `};
`;

const BuyNow = styled(Link)`
  position: absolute;
  bottom: 0;
  right: 10%;
  background: #00aced;
  padding: 1.2rem 2rem;
  border-radius: 4px 4px 0 0;
  color: white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  font-family: 'Black Ops One';
  text-transform: uppercase;

  ${mq.tabletSmall`
    background: ${colors.red};
  `};
  &:hover {
    text-decoration: none;
    color: white;
  }
  ${z.modal};
`;

const indexQuery = graphql`
  {
    bgp: file(relativePath: { eq: "final-red.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    bgpm: file(relativePath: { eq: "deported-final.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default function IndexPage() {
  const { openModal } = useContext(ModalContext);
  const data = useStaticQuery(indexQuery);
  return (
    <Fragment>
      <SEO title={homeStrings.title} description={homeStrings.description} />
      <Wrapper>
        <BgImg
          backgroundColor={colors.blue}
          fluid={data.bgp.childImageSharp.fluid}
        />
        <BgImgMobile
          backgroundColor={colors.blue}
          fluid={data.bgpm.childImageSharp.fluid}
        />
        <Container>
          <Inner>
            <Play onClick={() => openModal(modalTypes.TRAILER)} />
            <PlayText>PLAY TRAILER</PlayText>
          </Inner>
        </Container>
        <BuyNow to="/order-now">Order Now</BuyNow>
      </Wrapper>
    </Fragment>
  );
}
